<template>

    <div class="w-100">
        <!-- Header -->
        <div class="text-center mb-5">
            <p class="mb-3">
                <i class="fa fa-2x fa-circle-notch text-primary-light"></i>
            </p>
            <h1 class="fw-bold mb-2">
                Masuk
            </h1>
            <p class="fw-medium text-muted">
                Selamat datang, silahkan masuk atau <a href="/register">daftar</a> untuk membuat akun baru.
            </p>
        </div>
        <!-- END Header -->

        <!-- Sign In Form -->
        <!-- jQuery Validation (.js-validation-signin class is initialized in js/pages/op_auth_signin.min.js which was auto compiled from _js/pages/op_auth_signin.js) -->
        <!-- For more info and examples you can check out https://github.com/jzaefferer/jquery-validation -->
        <div class="row g-0 justify-content-center">
            <div class="col-sm-8 col-xl-8">
                <form class="js-validation-signin" @submit.prevent="Login">
                    <div class="mb-4">
                        <input type="text" class="form-control form-control-lg form-control-alt py-3" v-model="auth.email"
                            id="login-username" name="login-username" placeholder="Email" required>
                    </div>
                    <div class="mb-4">
                        <input type="password" class="form-control form-control-lg form-control-alt py-3" v-model="auth.password"
                            id="login-password" name="login-password" placeholder="Password" required>
                    </div>
                    <div class="d-flex justify-content-between align-items-center mb-4">
                        <div>
                            <a class="text-muted fs-sm fw-medium d-block d-lg-inline-block mb-1"
                                href="/forgot">
                                Lupa password?
                            </a>
                        </div>
                        <div>
                            <button type="submit" class="btn btn-lg btn-alt-primary" :disabled="loading.submit">
                                <i class="fa fa-fw fa-sign-in-alt me-1 opacity-50"></i> Masuk
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- END Sign In Form -->
    </div>

</template>

<script>

import axios from 'axios'
export default {
    components: {

    },

    data: () => ({
        hasVisiblePassword: false,
        auth: {
            email: '',
            password: ''
        },

        loading: {
            submit: false
        }

    }),

    methods: {
        Login() {
            this.loading.submit = true

            return new Promise(() => {
                axios.post('login', {

                    email: this.auth.email,
                    password: this.auth.password,

                }, {
                    headers: {
                        'Access-Control-Allow-Headers': '*',
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }).then(response => {

                    this.openNotification('top-right', 'success', '10000', 'Login Berhasil', response.data.message);
                    localStorage.setItem('access_token', response.data.data.APItoken)
                    this.$router.go()

                    // if(this.$route.query.redirect) {
                    //     this.$router.replace(this.$route.query.redirect)
                    // } else {
                    //     this.$router.replace('/dashboard')
                    // }

                    this.loading.submit = false

                })

                    .catch(error => {
                        this.openNotification('top-right', 'danger', '10000', 'Autentikasi Gagal', error.response.data.message);
                        this.loading.submit = false
                    })

            })
        },

        openNotification(position = null, color, duration, title, message) {
            this.$vs.notification({
                color,
                position,
                duration,
                title: title,
                text: message
            })
        },
    }
}

</script>

<style scoped>
    @import '../../../public/oneui/assets/css/oneui.min.css';
</style>